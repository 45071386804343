@use "sass:color";
@import "src/assets/colors";

button.colorful-icon-button {
    max-width: 2.1875rem;
    max-height: 2.1875rem;
    min-width: 2.1875rem;
    min-height: 2.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    user-select: none;
    transition: all 150ms ease-in-out;

    &:disabled {
        cursor: not-allowed;
    }

    &.variant-transparent {
        background: #fff;
        color: rgba($textColor3, 0.8);

        &:hover {
            color: $accentBlue;
        }
    }

    &.variant-blue {
        background: $lightBlueWithWhite;
        color: $accentBlue;

        &:hover {
            background: $lightBlueWithWhiteDarker;
        }
    }

    &.variant-blue-white {
        background: $accentBlue;
        color: $white;

        &:disabled,
        &:hover {
            background: color.scale($accentBlue, $lightness: 50%);
        }
    }

    &.variant-red {
        background: $lightRedWithWhite;
        color: $accentRed;

        &:hover {
            background: $lightRedWithWhiteDarker;
        }
    }

    &.variant-green {
        background: $lightGreenWithWhite;
        color: $accentGreen;

        &:hover {
            background: $lightGreenWithWhiteDarker;
        }
    }

    &.variant-orange {
        background: $lightOrangeWithWhite;
        color: $accentOrange;

        &:hover {
            background: $lightOrangeWithWhiteDarker;
        }
    }

    &.variant-white {
        background: rgba($white, 0.08);
        color: $white;

        &:hover {
            background: rgba($white, 0.1);
        }
    }
}
