@import "src/assets/colors";
@import "src/assets/text-styles";

.select-field__menu-portal {

    .select-field__menu {
        border-radius: 15px;
        overflow: hidden;
        border: 1px solid $accentBlue;
        //box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
        box-shadow: none;
        z-index: 150;
        width: unset;
        min-width: 100%;

        .select-field__menu-list {
            padding-bottom: unset;
        }

        .select-field__option {
            @extend .text-regular;
            @extend .text-14;

            white-space: nowrap;

            &--is-focused, &--is-selected {
                background: #f1f5ff;

                @extend .text-color-accent-blue;
            }

            &:last-of-type {
                padding: 0.5rem 0.75rem; // force override ( TODO add appointment patient picker )
            }
        }
    }
}

.select-field-container {
    display: flex;
    flex-direction: column;
    //height: 3.25rem;      // TODO HEIGHT

    .unknown-value {
        @extend .text-color-3;
        padding: 1.0625rem 1rem;
    }

    .input-label {
        display: flex;
        width: 100%;
        font-size: 0.875rem;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 0.1875rem;
        padding-left: 1rem;
        color: $textColor3;
        transition: all 200ms ease-in-out;

        .input-error {
            margin-left: 0.625rem;
            color: $accentRed;
            font-size: 0.75rem;
            padding-right: 0.8rem;
        }
    }

    .select-field {
        width: 100%;
        height: 100%;

        &__placeholder {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__value-container {
            //padding-left: 0.8125rem;        // TODO HEIGHT
            padding: 0.75rem 1rem;
        }

        &__single-value {
            color: $textColor1;
            margin-left: 0;
        }

        &__input-container {
            margin-left: 0;
        }

        &__control {
            height: 100%;
            border-radius: 15px;
            border: 1px solid $borderColor;
            box-shadow: none;
            transition: all 150ms ease-in-out;
            flex-wrap: nowrap;

            .select-field__indicator-separator {
                margin-top: 0;
                margin-bottom: 0;
                width: 2px;
                background: $borderColor;
            }

            &:hover {
                border: 1px solid $textColor4;

                .select-field__indicator-separator {
                    width: 1px;
                    background: $textColor4;
                }
            }

            &--is-focused {
                border: 1px solid $accentBlue !important;

                .select-field__indicator-separator {
                    background: $accentBlue !important;
                    width: 1px;
                }
            }

            .select-field__indicator {
                transition: all 150ms ease-in-out;
                color: $textColor1;
            }

            .select-field__clear-indicator {
                color: $textColor3;
                cursor: pointer;
            }

            &--menu-is-open {

                .select-field__indicator {
                    transform: rotateZ(180deg);
                }
            }

            .select-field__loading-indicator {
                color: $accentBlue;
            }
        }

        &__multi-value__label {
            color: $textColor1;
            font-size: 0.875rem;
        }

        &__multi-value {
            background: $lightBlueWithoutWhiteDarker;
        }

        &__multi-value__remove {
            cursor: pointer;

            &:hover {
                background: $lightRedWithWhite;
            }
        }

        &__menu {
            border-radius: 15px;
            overflow: hidden;
            border: 1px solid $accentBlue;
            //box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
            box-shadow: none;
            z-index: 150;

            .select-field__option {
                @extend .text-regular;
                @extend .text-14;

                &--is-focused, &--is-selected {
                    background: rgba(77, 124, 254, 0.08);

                    @extend .text-color-accent-blue;
                }
            }
        }
    }

    &.variant-dark {

        .select-field {

            &__single-value {
                @extend .text-medium;
                color: $textColor4;
            }

            &__control {
                .select-field__indicator {
                    transition: all 150ms ease-in-out;
                    color: $textColor4;
                }
            }
        }
    }

    &.readonly {

        .input-label {
            color: $textColor1;
        }

        .select-field {
            pointer-events: none;

            &__control {
                border-color: transparent;

                .select-field__indicator {
                    transition: all 150ms ease-in-out;
                    color: $textColor1;
                }
            }

            &__single-value {
                color: $textColor3;
            }

            &__indicators {
                opacity: 0;
            }
        }
    }

    &.error {

        .select-field {

            &__control {
                border-color: $accentRed !important;

                .select-field__indicator {
                    color: $accentRed !important;
                }

                .select-field__indicator-separator {
                    width: 1px;
                    background: $accentRed !important;

                    &:hover {
                        background: $accentRed !important;
                    }
                }
            }
        }
    }
}
