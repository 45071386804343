@import "src/assets/colors";

.alert-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.0);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 250ms ease-in-out;
    z-index: 100000;

    &.open {
        background: rgba(0, 0, 0, 0.25);

        .alert {
            opacity: 1;
        }
    }

    .alert {
        border: 1px solid $borderColor;
        border-radius: 20px;
        background: #ffffff;
        padding: 2.5rem 3.5625rem;
        user-select: none;
        transition: all 250ms ease-in-out;
        opacity: 0;

        &.size-small {
            max-width: 23.125rem;
            min-width: 23.125rem;
        }

        .body {
            margin-top: 1.75rem;
        }

        .footer {
            margin-top: 2.9375rem;
            align-items: center;
            justify-content: flex-end;
            display: flex;
        }

        .header {
        }

        &.variant-red {
            .title {
                color: $accentRed;
            }
        }

        &.variant-orange {
            .title {
                color: $accentOrange;
            }
        }
    }
}
