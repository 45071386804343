@import "src/assets/colors";
@import "src/assets/text-styles";


.search-input {
    //don't use this
    //height: 100%;

    &.bottom-border {


        .input-container {
            //height: 3.25rem;          // TODO HEIGHT

            .inner {
                border: none;
                border-bottom: 1px solid $borderColor;
                border-radius: 0;

                input {
                    padding-left: 0.9375rem;

                    &::placeholder {
                        color: $accentBlue;
                        font-weight: normal;
                        opacity: 1;
                    }
                }

                .icon-container {
                    border: none;
                }
            }
        }
    }

    &.full-border {

        .input-container {
            background-color: #FFFFFF;
            border-radius: 0.9375rem;

            .inner {
                height: 100%;

                input {
                    @extend .text-14;
                    @extend .text-medium;
                    @extend .text-color-4;

                    pointer-events: fill;
                }
            }

            .icon-container svg {
                color: $textColor4;
            }

            &.readonly {
                .inner {
                    border-color: $borderColor;

                    .icon-container {
                        border-color: $borderColor;
                    }

                }

                input {

                    &::placeholder {
                        @extend .text-medium;
                        @extend .text-14;

                        color: $textColor4;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
