@import "src/assets/colors";

.message-bar-action-dropup {
    margin-right: 0.5rem;

    .dropdown-anchor-wrapper {
        color: $textColor3;
        cursor: pointer;

        &.open {
            svg {
                color: $accentBlue
            }
        }
    }
}
