.entity-toolbar {
    display: flex;
    margin-bottom: 1.25rem;

    .add-entity-btn {
        width: fit-content;

        button {
            height: 100%;
            padding: 1rem 2.5rem;
        }
    }
}
