.animated-hider {
    .animated-hider-inner {
        overflow: hidden;
        width: 100%;
    }

    &.variant-collapse {
        display: flex;
        flex-direction: column;
    }
}
