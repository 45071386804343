@import "../../../assets/colors.scss";
@import "../../../assets/vars.scss";
@import "src/assets/misc";

.main-menu {
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    width: 6.875rem;
    flex: 1 0 auto;
    //margin-top: $headerHeight + 1rem;
    overflow: auto;
    padding-bottom: 1rem;

    .menu-items {
        overflow: auto;
    }

    .main-logo-container {
        @extend .flex-center-both;
        height: $headerHeight + 1rem;
        width: 100%;
        flex-shrink: 0;

        img {
            width: 40%;
            object-fit: cover;
        }
    }

    .footer-logo {
        flex-shrink: 0;
        width: 100%;
        height: 20px;
        object-fit: contain;
        margin-top: auto;
        margin-bottom: 1.5rem;
    }

    .animated-hider {
        overflow: hidden;
    }

    .menu-item {
        width: 100%;
        user-select: none;
        cursor: pointer;
        align-items: center;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;

        .menu-item-inner {
            width: 77%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .icon-expansion-container {
            position: relative;
            display: flex;
            align-items: center;
        }

        .expander-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -55%;
            top: 27%;
            min-width: 1.375rem;
            min-height: 1.375rem;
            max-width: 1.375rem;
            max-height: 1.375rem;
            transition: all 150ms ease-in-out;

            &.expanded {
                transform: rotate(-180deg);
            }
        }

        .icon-container {
            transition: all 125ms ease-in-out;
            width: 2.875rem;
            height: 2.875rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid $borderColor;
            border-radius: 10px;
            color: $textColor3;
        }

        .title {
            transition: all 125ms ease-in-out;
            padding-top: 0.25rem;
            color: $textColor3;
            text-align: center;
        }

        &.isSubroute {
            margin-bottom: 0;

            .menu-item-inner {
                border-left: 2px solid $borderColor;
            }

            .icon-container {
                height: 1.875rem;
                padding-top: 0.4375rem;
            }

            .icon-container {
                border: none;
            }
        }

        &.isSelected {
            &.isSubroute {
                .menu-item-inner {
                    border-left: 2px solid $accentBlue;
                }
            }

            .icon {
                color: $accentBlue;
            }

            .title {
                color: $accentBlue;
            }
        }
    }
}
