@import "src/assets/colors";
@import "src/assets/text-styles";
@import "src/assets/misc";

.predefined-message-card-container {
    .predefined-message-card {
        @extend .flex-column, .text-15;
        border-radius: 15px;
        background: $background;
        padding: 1rem 1.25rem;
        color: $textColor4;
        margin-bottom: 0.625rem;

        .add-a-message-btn {
            user-select: none;
        }

        .card-header {
            @extend .flex-row, .align-center, .w-100p;
            user-select: none;

            .card-expanded-indicator {
                @extend .text-color-3;

                transition: all 150ms ease-in-out;
            }

            .edit-title-icon {
                @extend .text-color-3;

                &:hover {
                    color: $accentBlue;
                }
            }

            &.expanded {
                color: $accentBlue;

                .card-expanded-indicator {
                    transform: rotateZ(180deg);
                }
            }

            .animated-hider-inner {
                height: 18px;
            }

            .card-title {
                min-width: 12.5rem;
                max-width: 12.5rem;
            }
        }

        .message-template {
            @extend .flex-row, .w-100p;
            margin-top: 1rem;
            user-select: none;

            .template-textarea-media-uploader-container {
                flex: 1;
                display: flex;
                flex-direction: column;
            }

            .message-template-view {
                display: flex;
                flex-direction: column;
            }

            &.edit-active:not(.editing-self) {
                .template-text {
                    opacity: 0.4;
                    @extend .text-color-4;
                }
            }

            .template-icons-container {
                user-select: none;
                @extend .flex-column, .align-center;
                justify-content: flex-start;
                min-width: 3.125rem;
                margin-left: auto;
            }

            .ml-34 {
                margin-left: 2.125rem;
            }

            .checkbox-container {
                align-items: flex-start;
            }

            .template-text {
                @extend .mt-8;
                word-break: break-all;
                min-width: 14.0625rem;
            }

            .template-textarea-field {
                @extend .mt-8;
                min-width: 14.0625rem;
                width: 100%;

                textarea {
                    width: 100%;
                    @extend .text-14, .text-regular;
                    padding: 0.5rem;
                    border: none;
                    border-radius: 10px;
                    background: #fafdff;
                }
            }
        }

        .message-input-field {
            @extend .mt-8, .mr-16;

            .inner {
                input {
                    padding: 0;
                }

            }
        }
    }

    .template-icons-container {
        @extend .flex-column, .align-center;
        justify-content: flex-start;

        .icon-wrapper {
            @extend .flex-center-both;
            transition: all 125ms ease-in-out;
            width: 2rem;
            height: 2rem;
            border: 2px solid rgba($textColor3, 0.4);
            border-radius: 7px;
            color: $textColor3;
            cursor: pointer;
            margin-left: auto;
        }

        .blue-icon {
            border-color: $accentBlue;

            svg {
                color: $accentBlue;
            }

            &:hover {
                background: $accentBlue;

                svg {
                    color: $white;
                }
            }
        }

        .gray-icon {
            margin-top: 0.625rem;

            &:hover {
                background: $textColor3;

                svg {
                    color: $white;
                }
            }
        }
    }

    .category-input-field {
        @extend .w-100p;

        .inner {
            height: 100%;
        }
    }
}
