@import "src/assets/vars";
@import "src/assets/colors";

.textarea-field {
    font-weight: 400;
    transition: all 200ms ease-in-out;

    .input-label {
        display: flex;
        width: 100%;
        font-size: 0.875rem;
        color: $textColor3;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 0.1875rem;
        padding-left: 1rem;
    }

    textarea {
        resize: none;
        width: 100%;
        border: 2px solid $borderColor;
        border-radius: 0.9375rem;
        font-size: 1rem;
        color: $textColor1;
        padding: 1rem;
        box-sizing: border-box;
        transition: all 200ms ease-in-out;
        font-family: inherit;

        &:focus {
            outline: none;
            border-color: $accentBlue;
        }

        &::placeholder {
            opacity: 0.4;
        }
    }

    &.readonly {

        .input-label {
            color: $textColor1;
        }

        textarea {
            border-color: transparent;
            color: $textColor3;
            pointer-events: none;
        }
    }

    .input-error {
        margin-left: 0.625rem;
        color: $accentRed;
        font-size: 0.75rem;
        padding-right: 0.8rem;
    }

}
