@import "src/assets/vars";
@import "src/assets/colors";
@import "src/assets/text-styles";

button.mpdz {
    width: fit-content;
    padding: 0.75rem 1.5625rem;
    cursor: pointer;
    transition: all ease-in 175ms;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;

    &:hover {
        opacity: 0.8;
    }

    &:focus {
        outline: none;
    }

    &.transparent {
        background: transparent;
    }

    &.white {
        background: white;
    }

    &.white50 {
        background: $borderColor50;
        color: $textColor3;
        border: 1px solid $borderColor;
    }

    &.red {
        background: $accentRed;
        color: #fff;
        border: 1px solid $accentRed;

        &.inversed {
            border: 1px solid $borderColor;
            background: transparent;
            color: $accentRed;
        }
    }

    &.orange {
        background: $accentOrange;
        color: #fff;
        border: 1px solid $accentOrange;

        &.inversed {
            border: 1px solid $borderColor;
            background: transparent;
            color: $accentOrange;
        }
    }

    &.transparent-black {
        background: transparent;
        color: $textColor4;
        border: 1px solid transparent;

        &:hover {
            color: $textColor3;
        }
    }

    &.blue {
        background: $accentBlue;
        color: #fff;
        border: 1px solid $accentBlue;

        &.inversed {
            border: 1px solid $borderColor;
            background: transparent;
            color: $accentBlue;
        }
    }

    &.lightblue {
        background: $lightBlueWithoutWhite;
        color: $accentBlue;
        border: none;

        &:hover {
            background: $lightBlueWithoutWhiteDarker;
            opacity: 1;
        }

        &.inversed {
            border: 1px solid $lightBlueWithoutWhite;
            color: $lightBlueWithoutWhite;
            background: #fff;
        }
    }

    &.text {
        background: #fff;
        border: 1px solid transparent;

        &:hover {
            opacity: 0.8;
        }
    }

    .spinner-container {
        margin-left: 0.5rem;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
}
