@import "src/assets/text-styles";

.date-range-picker {
    z-index: 10;
    border-radius: 15px;

    .picker {
        position: fixed;
        background: white;
        border-radius: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        padding: 1.125rem 0.875rem;
        min-width: 25rem;
        max-width: 25rem;
        min-height: 23.125rem;
        display: flex;
        flex-direction: column;
        z-index: 10;

        .move-header {
            display: flex;
            justify-content: space-between;
            user-select: none;
            padding-left: 0.35rem;
            padding-right: 0.1rem;
            padding-bottom: 1.5rem;

            .shown-date {
                @extend .text-regular;
                @extend .text-16;
                @extend .text-color-4;

                text-transform: capitalize;

                span {
                    cursor: pointer;
                }
            }
        }

        .day-spacer,
        .weekday-name,
        .day {
            min-height: 2.5rem;
            max-height: 2.5rem;
            min-width: 2.5rem;
            max-width: 2.5rem;
        }

        .weekday-names-header {
            display: flex;
            justify-content: space-between;

            .weekday-name {
                min-height: unset;
                max-height: unset;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: capitalize;
                user-select: none;

                @extend .text-medium;
                @extend .text-color-3;
                @extend .text-14;
            }

        }

        .year-months {
            display: flex;
            flex-wrap: wrap;
            flex: 1;

            .month {
                width: 33%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: capitalize;
            }
        }

        .month-days {
            display: flex;
            flex: 1;
            justify-content: space-around;
            flex-direction: column;

            .week {
                display: flex;
                justify-content: space-between;

                .day {
                    user-select: none;
                    border-radius: 100px;
                    padding: unset;
                }
            }
        }
    }
}
