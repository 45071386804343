@import "src/assets/text-styles";
@import "src/assets/colors";

.messenger {
    background: $background;
    border: 1px solid $borderColor;
    border-top: none;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    position: relative;

    .messenger-dropzone-overlay {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $dropzoneTintColor;

        .dropzone-overlay-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .drag-file-here-text {
                @extend .text-medium;
                @extend .text-16;
                @extend .text-color-accent-green;

                margin-top: 1rem;
            }

            svg {
                color: $accentGreen;
            }

        }
    }
}
