@import "../../../../assets/colors.scss";
@import "src/assets/text-styles";

.impersonation-dropdown {
    width: 156px;
    z-index: 100;

    .dropdown-inner {
        .dropdown-row {
            &.logout {
                color: $accentRed;
            }
        }
    }
}

.impersonation-modal-container {
    display: flex;
    flex-direction: column;
    width: 23.375rem;
    height: 19.75rem;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 2.5rem 3.5625rem;

    .hc-picker {
        margin-top: 1.5rem;
        @extend .text-14;
    }

    .team-picker {
        margin-top: 1.125rem;
        @extend .text-14;
    }

    .title {
        color: $accentBlue;
    }

    .footer {
        display: flex;
        margin-left: auto;
        margin-top: auto;
    }
}
