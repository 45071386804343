@import "src/assets/colors";
@import "src/assets/text-styles";
@import "src/assets/misc";

.questionnaire-card {
    @extend .flex-column, .text-14;
    border-radius: 15px;
    background: $white;
    padding: 1rem 0.75rem;
    margin-left: 1rem;
    color: $textColor4;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;

    &.type-Input {
        background: $lightBlueWithWhite;
    }

    &.type-Select {
        background: $lightGreenWithWhite;
    }

    &.type-File {
        background: $lightPurpleWithWhite;
    }

    &.type-answer {
        background: $lightOrangeWithWhite;
    }

    &.type-Header {
        background: $lightRedWithWhite;
    }

    .add-a-message-btn {
        user-select: none;
    }

    .card-header {
        @extend .flex-row, .align-center, .w-100p;
        user-select: none;
        cursor: pointer;

        .move-icon {
            &.disabled {
                @extend .text-color-3;
                cursor: default;
            }
        }

        .card-expanded-indicator {
            @extend .text-color-3;

            transition: all 150ms ease-in-out;
        }

        .header-icon {
            @extend .text-color-3;

            &:hover {
                color: $accentBlue;
            }
        }

        &.expanded {
            .card-expanded-indicator {
                transform: rotateZ(180deg);
            }
        }

        .animated-hider-inner {
            height: 18px;
        }

        .question-text {
            min-width: 12.5rem;
            max-width: 12.5rem;
        }
    }
}
