@import "src/assets/misc";

.lead-files {
    @extend .flex-column, .flex-scroll-y;

    .files-waypoint {
        padding-top: 1rem;
        padding-bottom: 1rem;
        min-height: 35px;
        box-sizing: content-box;
    }
}
