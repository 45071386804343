@import "src/assets/misc";
@import "src/assets/text-styles";

.lead-communication {
    height: 100%;

    .search-input {
        width: 18.75rem;
        margin-bottom: 1.25rem;

        &.in-header {
            width: 100%;
            margin-bottom: 0;

            .input-container {

                .inner {
                    border: none;

                    input {
                        padding-left: 0;
                    }

                    .icon-container {
                        border: none;
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .lead-container {
        //height: 100%;
        overflow: auto;
    }

    .lead-list {
        display: flex;
        flex-direction: column;

        .header {
            border-bottom: 1px solid $borderColor;
            min-height: 3.3125rem;
            border-top-left-radius: 20px;
            display: flex;
            align-items: center;
            padding-left: 1.25rem;
            background: $white;
            padding-right: 1.25rem;
            transition: all 120ms ease-in-out;

            &.multiselect {
                background: $accentBlue;
            }

            .header-multiselect {
                min-width: 4rem;
                display: flex;
                justify-content: center;

                .checkbox-container {
                    .checkmark {
                        width: 1.5rem;
                        height: 1.5rem;

                        &:after {
                            width: 0.3rem;
                            height: 0.6rem;
                            top: 0.1rem;
                            left: 0.43rem;
                        }
                    }
                }
            }

            .lead-filter {

                .lead-filter-icon {
                    width: 1.5625rem;
                    height: 1.5625rem;
                    color: $textColor3;
                    cursor: pointer;
                    transition: all 200ms ease-in-out;

                    &.opened {
                        color: $textColor4;
                    }

                    &:hover {
                        color: $textColor4;
                    }
                }

                .reason-list {
                    display: flex;
                    flex-direction: column;
                    padding: 0.6rem;
                    text-align: center;

                    .reason:not(:last-of-type) {
                        margin-bottom: 0.5rem;
                    }

                    .reason {
                        cursor: pointer;
                        color: $textColor3;
                        transition: all 200ms ease-in-out;

                        &:hover {
                            opacity: 0.9;
                        }

                        &.active {
                            color: $textColor4;
                        }
                    }
                }
            }
        }

        .lazy-load-list {
            .lead-list-item {
                padding-left: 1.25rem;
                padding-right: 1.25rem;
            }

            .no-results {
                padding-bottom: 50px;
            }
        }

        .footer {
            min-height: 4rem;
            max-height: 4rem;
            background: #FFFFFF;
            border-bottom-left-radius: 20px;
            display: flex;
            align-items: center;
            padding: 0 1.25rem;
            text-transform: lowercase;
            justify-content: flex-end;
            border-top: 1px solid $borderColor;

            @extend .text-14;
        }
    }

    &.panel-open {
        .lazy-load-list {
            min-width: 16.875rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            transition: all 300ms ease-in-out;
            transition-property: border-top-right-radius, border-bottom-right-radius;
        }
    }

    .lead-list {
        box-shadow: unset;
        overflow: hidden;


    }

    .lead-status {
        $iconSize: 1.09375rem;

        .status-icon {
            width: $iconSize;
            height: $iconSize;
            color: $textColor3;
            stroke-width: 1.16px;
        }

        .right {
            @extend .flex-column, .ml-4, .align-center;
            padding-top: 0.125rem;
        }

        .rating {
            @extend .flex-center-both, .text-color-3;
            width: 0.875rem;
            height: 0.875rem;
            border-radius: $iconSize;
            border: 1.16px solid $textColor3;
            font-weight: 500;
            font-size: 0.5625rem;
            margin-bottom: 0.4375rem;

            &.right-padding {
                padding-right: 0.0625rem;
            }
        }

        .not-verified {
            color: $accentOrange;
        }

        .is-satisfied {
            color: $accentGreen;
        }

        .not-satisfied {
            color: $accentRed;
        }
    }

    .inactive-leads-actions {
        max-width: 4.25rem;
        margin-left: auto;
    }

    .filter-icon {
        &.active {
            color: $accentBlue;
        }
    }

    .filters-dropdown {
        .filters-title {
            padding: 1rem;

            border-bottom: 1px solid $lightBlueWithWhite;
            margin-bottom: 1rem;
        }

        .dropdown-row {
            background: white;
            padding: 0.5rem 1rem 0.5rem 1rem;
            min-width: 12.5rem;

            &:last-of-type {
                padding-bottom: 1rem;
                border-bottom: 1px solid $lightBlueWithWhite;
            }

        }

        .apply-filters-btn {
            margin: 1rem 1rem 1rem auto;
        }

    }
}
