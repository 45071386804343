
@keyframes alert-open {
    from {
        background: rgba(0, 0, 0, 0.0)
    }

    to {
        background: rgba(0, 0, 0, 0.25);
    }
}
