@import "colors";
@import "vars";
@import "misc";
@import "entity-details";
@import "text-styles";

* {
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
}

img {
    display: block;
}

html,
body,
#root {
    font-size: 16px;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    height: 100%;
    font-family: "Rubik", Arial, Helvetica, sans-serif;
}

.App {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .app-content-menu-container {
        display: flex;
        overflow: auto;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .app-spinner-container {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .app-content {
        height: calc(100% - $headerHeight);
        overflow: auto;
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: $background;
        justify-content: center;
        align-items: center;
    }

    .__react_component_tooltip {
        background: $textColor1;
        border-radius: 8px;

        &.variant-white {
            background: $white;
            border: 1px solid $borderColor;
            color: $textColor3;

            &.place-bottom:before {
                border-bottom: 8px solid $borderColor;
            }

            &.place-bottom::after {
                border-bottom-color: $white;
            }

            &.place-top:before {
                border-top: 8px solid $borderColor;
            }

            &.place-top::after {
                border-top-color: $white;
            }

            &.place-right:before {
                border-right: 8px solid $borderColor;
            }

            &.place-right::after {
                border-right-color: $white;
            }

            &.place-left:before {
                border-left: 8px solid $borderColor;
            }

            &.place-left::after {
                border-left-color: $white;
            }
        }
    }
}

.page {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.875rem;
    width: 100%;
    flex: 1;
    max-width: 1920px;
    max-height: 100%;
    box-sizing: border-box;

    .table {
        display: flex;
        flex: 1 0 auto;
    }

    $formTopOffset: 4.5625rem;

    .form {
        display: flex;
        box-shadow: 0 0 64px rgba(0, 11, 27, 0.1);
        background: #ffffff;
        border-radius: 20px;

        &.offset-top {
            margin-top: $formTopOffset;
        }

        &.no-shadow {
            box-shadow: none;
        }

        &.form-10 {
            flex: 10;
        }

        &.form-6 {
            flex: 6;
        }

        &.form-5 {
            flex: 2.1;
        }

        &.form-4 {
            flex: 0.9;
        }

        &.form-3 {
            flex: 1.1;
        }

        &.form-2 {
            flex: 0.65;
        }

        &.hidden {
            margin-left: 0;
            flex: 0;
        }
    }
}

body.swal2-height-auto {
    height: 100% !important;
}

body.swal2-toast-shown .swal2-container {
    // TODO, reverted
    //width: 500px !important;
}

.swal2-title {
    word-break: break-word;
}

/*
 *  STYLE 2
 */

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px $accentBlue;
    background-color: $lightBlueWithWhite;
}
