@use "sass:color";
@import "src/assets/colors";
@import "src/assets/misc";


.table-column-horizontal {
    display: flex;
}

// actual sizing and layout css
.custom-table {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.full-height {
        flex: 1;
    }

    .header {
        transition: all 200ms ease-in-out;
    }


    &.multiselect {
        .header {
            background-color: $accentBlue;

            .multiselect-header {
                width: 100%;
            }
        }

        .header-content {
            color: #FFFFFF;
        }
    }

    .header-content {
        flex: 1;
        display: flex;

        .header-column {
            flex: 1;
            display: flex;

            .sort-arrows {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .content {
        @extend .flex-scroll-y;
        width: 100%;
        display: flex;
        flex-direction: column;

        .table-row-content {
            flex: 1;
            display: flex;
            min-height: 4.375rem;
            height: 4.375rem;

            .content-row-column {
                flex: 1;
            }
        }
    }
}

// how stuff looks CSS
.custom-table {
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 124px 0px #000B1B1A;
    overflow: hidden;

    .header {
        height: 3.3125rem;
        border-bottom: 1px solid $borderColor;
    }

    .header-content {
        margin-left: 2.75rem;
        margin-right: 2.75rem;
        display: flex;
        align-items: center;
        height: 100%;
        user-select: none;

        .header-column {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .sort-arrows {
            padding-left: 0.45rem;
            padding-right: 0.45rem;
            cursor: pointer;

            &.active {
                color: $accentBlue;
            }

            .material-icons-round {
                margin-top: -0.4rem;
                margin-bottom: -0.4rem;
                font-size: 1.25rem;
                user-select: none;
            }
        }
    }

    .content {
        .table-row {
            cursor: pointer;
            user-select: none;
            transition: all 100ms cubic-bezier(0.86, 0, 0.07, 1);
            background: #fff;

            &.selected {
                background: $lightBlueWithWhite;
            }

            .table-row-content {
                margin-left: 2.75rem;
                margin-right: 2.75rem;
                display: flex;
                align-items: center;
                border-bottom: 1px solid $lightBlueWithWhite;
            }

            &:hover {
                background: color.change($lightBlueWithWhite, $alpha: 0.08);
            }
        }
    }

    .footer {
        border-top: 1px solid $borderColor;
        height: 4rem;
        padding-left: 2.75rem;
        padding-right: 2.75rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .pagination {
            display: flex;
        }

        .page-numbers {
            display: flex;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        button.prev-next-btn {
            color: $accentBlue;
            padding-left: 0;
            padding-right: 0;

            .material-icons-round {
                font-size: 1rem;
            }
        }

        button.mpdz.page-button {
            margin-left: 0.17rem;
            margin-right: 0.17rem;
            padding-left: 1.0625rem;
            padding-right: 1.0625rem;

            &.current-page {
                border: 1px solid $borderColor;
                color: $accentBlue;
            }

            &:hover {
                border: 1px solid $accentBlue;
            }
        }
    }
}
