@import "src/assets/colors";
@import "src/assets/text-styles";

.lead-panel {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
    background-color: $white;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: row;

    .input-italic {
        input {
            font-style: italic;
        }
    }

    &.empty {
        align-items: center;
        justify-content: center;
        background: $background;
    }

    .messenger {
        flex: 1 0;
    }

    .lead-details {
        flex: 1 0;

        .manage-panel-header-icons {
            display: flex;
        }

        // override
        .tabs-container {
            background: $white;
            height: 3.3125rem;

            .tabs {
                border-radius: 0;
                height: 100%;

                .tab {
                    display: flex;
                    justify-content: center;
                    height: unset;
                    background: $white;
                    color: $textColor4;
                    font-weight: normal;
                    transition: all 150ms ease-in-out;
                    border-bottom: 2px solid $borderColor;

                    &.active {
                        color: $accentBlue;
                        font-weight: 500;
                        border-bottom: 2px solid $accentBlue;
                    }
                }
            }
        }

        .header {
            @extend .text-light, .text-14;
            display: flex;
            justify-content: space-between;
            color: $textColor3;
            padding: 0.75rem 1.25rem;

            svg {
                width: 1.25rem;
                height: 1.25rem;
            }
        }
    }
}
