@import "src/assets/colors";

.ambulances {
    transition: all 200ms ease-in-out;

    .phone-text {
        margin-top: 0.3125rem;
    }

    .custom-table {
        flex: 1;


        .content-row-column, .header-column {
            //padding-left: 1rem;
        }
    }

    .entity-toolbar {

        .add-entity-btn {
            margin-left: 1.25rem;
        }
    }

    .actions {
        display: flex;
    }

    .search-ambulance {
        height: 100%;

        .input-container {
            height: 100%;
        }
    }
}
