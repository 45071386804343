@import "src/assets/colors";

.login {
    justify-content: center;
    align-items: center;
    max-width: none;

    .outer-container {
        background: #fff;
        border-radius: 20px;
        width: 480px;
        padding: 4.3rem 0;

        .inner-container {
            max-width: 280px;
            display: flex;
            flex-direction: column;
            text-align: center;
            margin: auto;

            .input-container {
                margin-top: 1.25rem;
            }

            .title {
                font-size: 1.5rem;
                margin-bottom: 1.5rem;
            }

            .forgot-pass-btn {
                font-size: 0.875rem;
                color: $textColor4;
                margin-left: auto;
                margin-top: 5px;
            }

            .login-btn {
                text-transform: uppercase;
                margin-top: 1.875rem;
                padding: 18px 0;
                width: 100%;
            }

            .error-msg {
                color: $accentRed;
                margin-top: 1rem;
                text-align: left;
            }
        }
    }
}