@use "sass:color";

$textColor1: #252631;
$textColor3: #98A9BC;
$textColor4: #55566F;
$borderColor: #E8ECEF;
$accentBlue: #0095FF;
$accentRed: #F34751;
$accentGreen: #14AA8E;
$accentOrange: #ffab2b;
$tabBackground: #f5f7fd;
$systemOrange: #FF7A00;
$accentPurple: #f54fff;

$background: #F1F3FC;

$dropzoneTintColor: rgba($accentBlue, 0.24);


$lightPurpleWithWhite: rgba($accentPurple, 0.06);
$lightPurpleWithWhiteDarker: color.scale($lightPurpleWithWhite, $lightness: -20%);
$lightRedWithWhite: rgba($accentRed, 0.06);
$lightRedWithWhiteDarker: color.scale($lightRedWithWhite, $lightness: -20%);
$lightBlueWithWhite: rgba($accentBlue, 0.06);
$lightBlueWithWhiteDarker: color.scale($lightBlueWithWhite, $lightness: -20%);
$lightBlueWithoutWhite: rgba($accentBlue, 0.06);
$lightBlueWithoutWhiteDarker: color.scale($lightBlueWithoutWhite, $lightness: -20%);
$lightGreenWithWhite: rgba($accentGreen, 0.06);
$lightGreenWithWhiteDarker: color.scale($lightGreenWithWhite, $lightness: -20%);
$lightOrangeWithWhite: rgba($accentOrange, 0.06);
$lightOrangeWithWhiteDarker: color.scale($lightOrangeWithWhite, $lightness: -20%);

$lightRed: #fdf0ef;
$borderColor50: rgba($borderColor, 0.5);
$white: #fff;
$whiteTint: rgba($white, 0.5);
$black: #000;

$lightBrown: #fcf8e3;
$accentBrown: #957b4b;

@function linearGradientBg($bgColor) {
    @return linear-gradient(0deg, $bgColor, $bgColor), #FFFFFF;
}
