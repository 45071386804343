@import "src/assets/misc";

.manage-user {

    .user-role-picker {
        flex: 2;
    }

    .internal-id {
        flex: 1;
    }

    .input-container, .select-field-container {
        //margin-bottom: 1.25rem;
        //height: 4.0625rem;        // TODO HEIGHT
    }

    .passwords-container {
        @extend .flex-row;

        align-items: flex-end;
    }
}
