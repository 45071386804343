@import "src/assets/colors";
@import "src/assets/text-styles";
@import "src/assets/misc";

.questionnaire {

    .questionnaire-picker {
        display: flex;

        .spinner-container,
        .select-questionnaire {
            flex: 1;
        }
    }

    .questions-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        overflow: hidden;
        border-radius: 15px;

        .questions-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            background: $white;
            margin-bottom: 4rem;

            .questions-header {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                height: 3.3125rem;
                border-bottom: 1px solid #E8ECEF;
                padding: 0 1.25rem;
                font-size: 1.125rem;
            }

            .questions-body {
                padding: 1rem 2rem 1rem 0.75rem;
                display: flex;
                flex-direction: column;
                overflow: auto;
                flex-basis: 0;
                flex-grow: 1;
            }

            .questions-footer {
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                width: 100%;
                height: 4rem;
                border-top: 1px solid $borderColor;
                background-color: #ffffff;
                padding-left: 1.25rem;

                .add-root-question-btn {
                    height: 2.5rem;
                }
            }
        }
    }
}
