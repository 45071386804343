@import "src/assets/vars";
@import "src/assets/colors";
@import "src/assets/misc";
@import "src/assets/text-styles";

.input-container {
    font-weight: 400;
    transition: all 200ms ease-in-out;

    .input-label {
        display: flex;
        width: 100%;
        font-size: 0.875rem;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 0.1875rem;
        padding-left: 1rem;
        color: $textColor3;
        transition: all 200ms ease-in-out;
    }

    .inner {
        display: flex;
        width: 100%;
        border-radius: 0.9375rem;
        border: 2px solid $borderColor;
        transition: all 200ms ease-in-out;

        input {
            flex: 1;
            width: 100%;
            border-radius: 0.9375rem;
            border: none;
            font-size: 1rem;
            color: $textColor1;
            font-weight: 500;
            padding: 1rem;
            box-sizing: border-box;
            transition: all 200ms ease-in-out;
            outline: none;

            &::placeholder {
                opacity: 0.4;
            }
        }

        .cleave-input {
            &::placeholder {
                color: $textColor3;
                opacity: 1;
            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        .icon-container {
            display: flex;
            min-height: 100%;
            align-items: center;
            padding: 0 0.75rem;
            border-left: 2px solid $borderColor;
            cursor: pointer;

            svg {
                color: $textColor3;
                width: 20px;
                height: 20px;
            }
        }
    }

    &.focused {

        .inner {
            border-color: $accentBlue;

            .icon-container {

                border-color: $accentBlue;

                svg {
                    color: $accentBlue;
                }
            }
        }
    }

    &.readonly {
        .input-label {
            color: $textColor1;
        }

        .inner {
            border-color: transparent;

            input {
                color: $textColor3;
                pointer-events: none;
            }

            .icon-container {
                border-color: transparent;
            }
        }
    }

    &.error {
        .inner {
            border: 1px solid $accentRed;

            .icon-container {
                border-color: $accentRed;
            }
        }
    }

    .input-error {
        margin-left: 0.625rem;
        color: $accentRed;
        font-size: 0.75rem;
        padding-right: 0.8rem;
    }
}
