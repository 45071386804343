@import "src/assets/text-styles";
@import "src/assets/colors";

.date-range-picker-readonly {
    @extend .text-16;
    @extend .text-color-3;
}

.date-range-picker-container {
    display: flex;
    flex-direction: column;


    .input-label {
        display: flex;
        font-size: 0.875rem;
        color: $textColor1;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 0.1875rem;
        padding-left: 1rem;
    }

    .date-range-picker-input {
        display: flex;
        align-items: center;
        //width: fit-content;

        &.outlined {
            border: 1px solid $borderColor;
            border-radius: 0.9375rem;

            &:hover {
                border-color: $textColor1;

                .icon-container {
                    border-color: $textColor1;
                }
            }

            .picker-value {
                padding: 0 1rem;
            }
        }

        &.open {
            border: 1px solid $accentBlue;

            .icon-container {
                border-color: $accentBlue;
            }

            &:hover {
                border-color: $accentBlue;

                .icon-container {
                    border-color: $accentBlue;
                }
            }
        }

        .icon-tabler {
            color: $accentBlue;
            cursor: pointer;
        }

        .picker-value {
            text-transform: capitalize;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: $textColor1;
            user-select: none;
            z-index: 0;
        }

        .picker-input-content {
            cursor: pointer;
            width: 100%;
        }

        .icon-container {
            height: 100%;
            border-left: 1px solid $borderColor;
            padding: 0.75rem;
            cursor: pointer;
            margin-left: auto;

            svg {
                color: $textColor3;
                width: 20px;
                height: 20px;
            }
        }
    }
}
