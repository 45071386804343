@import "src/assets/colors";
@import "src/assets/text-styles";

.messenger-header-actions {
    display: flex;

    .icon {
        margin-left: 1rem;
        width: 1.25rem;
        height: 1.25rem;
        color: $textColor3;
    }
}