@import "colors";

.manage-entity-common {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: hidden;

    .header {
        width: 100%;
        height: 3.3125rem;
        border-bottom: 1px solid $borderColor;
        display: flex;
        align-items: center;
        padding: 0 1.25rem;
        font-size: 1.125rem;
        color: $textColor1;

        .edit-mode-btn {
            margin-left: auto;
        }

        .header-btn-container {
            display: flex;
            flex-direction: row;
            margin-left: auto;
        }
    }

    .entity-details-wrapper {
        overflow: hidden;
        min-width: 0px;
    }

    .entity-details-container {
        display: flex;
        flex-direction: column;
        flex-basis: 0;
        flex-grow: 1;
        overflow: auto;
        margin-bottom: 4rem;

        .entity-details {
            padding: 1.875rem 1.25rem;
            display: flex;
            flex-direction: column;
            overflow: auto;
            flex-basis: 0;
            flex-grow: 1;
            position: relative;

            .input-container,
            .select-field-container,
            .date-range-picker-input {
                margin-bottom: 1.25rem;
                //height: 4.0625rem;        // TODO HEIGHT
            }

            .form-row {
                display: flex;
                align-items: center;
                height: fit-content;
                margin-bottom: 1.875rem;

                &.align-start {
                    align-items: flex-start;
                }

                .row-label {
                    min-width: 10rem;
                    @extend .text-regular, .text-14, .text-color-3;
                }

                .input-container,
                .select-field-container {
                    margin-bottom: 0;
                    //height: 3.0625rem;   // TODO HEIGHT
                }
            }
        }
    }

    .buttons {
        display: flex;
        margin-top: auto;
        margin-left: auto;
    }

    .footer {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 4rem;
        border-top: 1px solid $borderColor;
        background-color: #ffffff;
        //padding-right: 1.875rem;      // TODO ?
        padding-right: 1.25rem;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        .footer-button {
            height: 2.5rem;
        }
    }

    .edit-mode-btn {
        position: absolute;
        top: 1.875rem;
        right: 1.25rem;
    }

    &.readonly {
        .entity-details-container .entity-details .form-row .row-label {
            @extend .text-color-1;
        }
    }
}
