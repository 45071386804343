@import "src/assets/colors";
@import "src/assets/text-styles";

.messenger-header {
    height: 3.3125rem;
    border-bottom: 1px solid $borderColor;
    padding: 0 1.25rem;
    font-size: 1.125rem;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .communication-name {
        @extend .text-medium;
        @extend .text-16;
        @extend .text-color-accent-blue;

        &.text-italic {
            @extend .text-italic;
        }
    }
}
