@import "src/assets/text-styles";
@import "src/assets/colors";

.messenger-message-list {
    flex: 1;
    display: flex;
    overflow: auto;
    height: 100%;
    flex-direction: column;

    .message-list-date-header {
        @extend .text-regular;
        @extend .text-12;
        color: rgba($accentBlue, 0.8);

        padding: 1rem 1.5rem;
        text-align: center;
        display: flex;
        align-items: center;

        .date {
            margin: 0 0.5rem;
        }

        .line-left,
        .line-right {
            background: rgba($accentBlue, 0.4);
            height: 1px;
            flex: 1
        }
    }

    .message-async-container-and-waypoint {
        padding-top: 1rem;
        padding-bottom: 1rem;
        min-height: 35px;
        box-sizing: content-box;
    }

    .message-list {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}
