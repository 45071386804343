.text-btn {
    opacity: 0.8;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &:hover {
        opacity: 1;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-65 {
    flex: 0.65;
}

.flex-04 {
    flex: 0.4;
}

.flex-05 {
    flex: 0.5;
}

.flex-0333 {
    flex: 0.3333;
}

.flex-0666 {
    flex: 0.666;
}

.flex-03 {
    flex: 0.3;
}

.flex-01 {
    flex: 0.1;
}

.flex-09 {
    flex: 0.9;
}

.justify-center {
    justify-content: center;
}

.flex-between {
    justify-content: space-between;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.flex-center-both {
    display: flex;
    align-items: center;
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.flex-scroll-y {
    flex-basis: 0;
    flex-grow: 1;
    overflow-y: auto;
}

.default-transition {
    transition: all 200ms ease-in-out;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.w-100p {
    width: 100%;
}

.w-50p {
    width: 50%;
}

.w-fit-content {
    width: fit-content;
}

// margins and paddings

// ------ MARGINS ------

.m-auto {
    margin: auto;
}

// top

.mt-8 {
    margin-top: 0.5rem;
}

.mt-10 {
    margin-top: 0.625rem;
}

.mt-16 {
    margin-top: 1rem;
}

.mt-20 {
    margin-top: 1.25rem;
}

.mt-28 {
    margin-top: 1.75rem;
}

.mt-auto {
    margin-top: auto;
}

// right

.mr-10 {
    margin-right: 0.625rem;
}

.mr-12 {
    margin-right: 0.75rem;
}

.mr-16 {
    margin-right: 1rem;
}

.mr-20 {
    margin-right: 1.25rem;
}

.mr-auto {
    margin-right: auto;
}

// bottom

.mb-4 {
    margin-bottom: 0.25rem;
}

.mb-6 {
    margin-bottom: 0.375rem;
}

.mb-8 {
    margin-bottom: 0.5rem;
}

.mb-10 {
    margin-bottom: 0.625rem;
}

.mb-16 {
    margin-bottom: 1rem;
}

.mb-28 {
    margin-bottom: 1.75rem;
}

.mb-auto {
    margin-bottom: auto;
}

// left

.ml-4 {
    margin-left: 0.25rem;
}

.ml-6 {
    margin-left: 0.375rem;
}

.ml-10 {
    margin-left: 0.625rem;
}

.ml-14 {
    margin-left: 0.875rem;
}

.ml-16 {
    margin-left: 1rem;
}

.ml-20 {
    margin-left: 1.25rem;
}

.ml-30 {
    margin-left: 1.875rem;
}

.ml-auto {
    margin-left: auto;
}


// ------ PADDINGS ------

// all

.p-0 {
    padding: 0;
}

.p-16 {
    padding: 1rem;
}

.p-25 {
    padding: 1.5625rem;
}

// top

.pt-20 {
    padding-top: 1.25rem;
}

.pt-12 {
    padding-top: 0.75rem;
}

// right

.pr-5 {
    padding-right: 0.3125rem;
}

// left


// bottom

.pb-20 {
    padding-bottom: 1.25rem;
}
