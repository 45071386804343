@import "src/assets/colors";

.reports {
    .date-range-picker-container {
        padding: 0.75rem 1.5625rem;
        border-radius: 12px;
        background: #FFFFFF;
        border: 2px solid $borderColor;
    }

    .picker-button-container {
        display: flex;
        flex-direction: row;
        height: fit-content;
        align-items: center;

        .generate-report-button {
            margin-left: 1.5rem;
            border: 2px solid $accentBlue;
        }
    }
}
