@import "src/assets/colors";
@import "src/assets/text-styles";

.predefined-messages {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    position: relative;

    .header {
        width: 100%;
        height: 3.3125rem;
        border-bottom: 1px solid $borderColor;
        display: flex;
        align-items: center;
        padding: 0 1.25rem;
        border-top-right-radius: 20px;

        .header-title {
            @extend .text-medium;
            user-select: none;

            font-size: 1.125rem;
            color: $textColor4;
        }

        .search-icon {
            color: $textColor3;
            margin-left: auto;
        }

        .search-input {
            margin-bottom: 1.25rem;

            &.in-header {
                width: 100%;
                margin-bottom: 0;

                .input-container {

                    .inner {
                        border: none;

                        .icon-container {
                            border: none;
                        }
                    }
                }
            }
        }

        &.select-active {
            background: $accentBlue;
            color: $white;
        }
    }

    .predefined-messages-body {
        display: flex;
        flex-direction: column;
        flex-basis: 0;
        flex-grow: 1;
        overflow: auto;
        margin-bottom: 4rem;
        padding: 1.25rem;
    }

    .footer {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 4rem;
        border-top: 1px solid $borderColor;
        background-color: #ffffff;
        padding-right: 1.25rem;
        border-bottom-right-radius: 20px;

        .footer-button {
            height: 2.5rem;
        }
    }
}
