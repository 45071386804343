@use "sass:color";

@import "./src/assets/colors";
@import "./src/assets/text-styles";


.media-uploader {

    .media-uploader-dropzone {
        padding: 2rem;
        position: relative;
        background: $background;
        border: 1px dashed $accentBlue;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        min-height: 7.5rem;
        flex-direction: column;

        .dropzone-overlay {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $dropzoneTintColor;

            .dropzone-overlay-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .drag-file-here-text {
                    @extend .text-medium;
                    @extend .text-12;
                    @extend .text-color-accent-green;

                    margin-top: 1rem;
                }

                svg {
                    color: $accentGreen;
                }
            }
        }

    }

    .no-files {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        flex: 1;

        @extend .text-light;
        @extend .text-12;

        .add-media-text {
            padding-top: 0.5rem;
        }
    }


    .media-uploader-files {
        padding-top: 1.25rem;
        padding-bottom: 0.2rem;
        display: flex;
        flex-wrap: wrap;

    }

}


.attachment-container {
    margin-bottom: 1rem;
    width: fit-content;

    .file-attachment {
        flex-direction: column;
        cursor: pointer;
        width: 4rem;
        height: 4rem;

        .icon-tabler {
            margin-right: 0.75rem;
            min-height: 2rem;
            min-width: 2rem;
        }

        .filename {
            margin-top: 0.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }

        padding: 1rem;
        display: flex;
        align-items: center;

        @extend .text-regular;
        @extend .text-12;
        @extend .text-color-1;
    }

    .attachment {
        margin-right: 1rem;
        cursor: pointer;
        position: relative;
        z-index: 0;

        .remove-attachment-btn {
            min-width: 1.75rem;
            min-height: 1.75rem;
            max-width: 1.75rem;
            max-height: 1.75rem;
            position: absolute;
            top: -20%;
            right: -20%;
            background: color.adjust($lightRedWithWhite, $alpha: 0.3);

            z-index: 1;
        }

        &:hover {
            .image-tint {
                width: 4rem;
                height: 4rem;
                top: 0;
                left: 0;

                max-width: 100%;
                max-height: 100%;
            }
        }


        .image-tint {
            transition: max-height 220ms ease-in-out, max-width 220ms ease-in-out, top 220ms ease-in-out, left 220ms ease-in-out;
            top: 50%;
            left: 50%;
            max-height: 0;
            max-width: 0;

            position: absolute;
            background: color.adjust($accentBlue, $alpha: -0.6);
            object-fit: cover;
            border-radius: 10px;
        }

        .image-tint,
        img {
            object-fit: cover;
            width: 4rem;
            height: 4rem;

            border-radius: 10px;
        }
    }
}
