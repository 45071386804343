@import "../../../../../../assets/text-styles";
@import "../../../../../../assets/misc";

.media-object-card {
    @extend .flex-row;
    padding: 1.125rem 0;
    margin: 0 0.9375rem;
    border-top: 1px solid $borderColor;

    &:first-child {
        border-top: none
    }

    .file-icon {
        @extend .text-color-3, .flex-center-both;
        background: $lightBlueWithWhite;
        border-radius: 10px;
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.8125rem;
    }

    .gallery-img {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.8125rem;
        border-radius: 10px;
        object-fit: cover;
    }
}
