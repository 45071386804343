@import "src/assets/text-styles";
@import "src/assets/colors";
@import "src/assets/misc";

.message {
    width: fit-content;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    max-width: 75%;

    .message-bottom-content {
        padding-top: 0.2rem;
        justify-content: flex-start;
        flex-direction: row-reverse;
        align-items: center;
        display: flex;

        .message-status {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
            display: flex;
            align-items: center;

            &.status-SEEN {
                .icon-tabler {
                    color: $accentBlue;
                }
            }

            .icon-tabler {
                transition: all 220ms ease-in-out;
                width: 1rem;
                height: 1rem;
                color: $textColor3;
            }
        }

        .message-created-at {
            @extend .text-regular;
            @extend .text-12;
            @extend .text-color-3;
        }
    }

    .bubble {
        border-radius: 20px;
        overflow: hidden;

        .message-attachment {
            position: relative;

            .message-attachment-count-text {
                cursor: pointer;
                position: absolute;
                width: 100%;
                height: 100%;
                background: $whiteTint;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                @extend .text-medium;
                @extend .text-32;
            }

            img {
                width: 100%;
                // this is the thumb size that backend generates
                min-height: 256px;
                max-height: 256px;
                cursor: pointer;
                object-fit: cover;
            }
        }

        .file-attachments {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .file-attachment {
                margin-top: 1rem;
                margin-bottom: 1rem;
                width: fit-content;
                flex-direction: column;
                cursor: pointer;

                .icon-tabler {
                    margin-right: 0.75rem;
                    min-height: 2rem;
                    min-width: 2rem;
                }

                .filename {
                    margin-top: 0.75rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                padding: 1rem;
                display: flex;
                align-items: center;

                @extend .text-regular;
                @extend .text-14;
                @extend .text-color-1;
            }

        }

        .message-content {
            word-break: break-word;
            padding: 1rem;
            @extend .text-regular;
            @extend .text-14;
            @extend .text-color-1;
        }
    }

    &.inbound {
        .message-bottom-content {
            justify-content: flex-end;
        }

        .bubble {
            border: 1px solid $borderColor;
            border-top-left-radius: 0px;
            background: $white;
        }
    }

    &.outbound {
        align-self: flex-end;

        .message-bottom-content {
            text-align: right;
        }

        .bubble {
            border: 1px solid $accentBlue;
            border-bottom-right-radius: 0px;
        }
    }

    &.questionnaire, &.disclaimer, &.opt-in, &.opt-out {
        width: 100%;
        max-width: 100%;

        .bubble {
            border-radius: 1.25rem;
            border: 1px solid transparent;
            background: transparent;
        }


        .message-content {
            @extend .flex-column;
            width: 100%;
            background: $white;
            border-radius: 0.9375rem;
            padding: 0;
            word-break: break-word;

            .title {
                @extend .text-white, .text-bold;
                background: $systemOrange;
                text-align: center;
                padding: 1rem;
            }

            .entries {
                @extend .flex-column;
                padding: 0.625rem;
            }

            .message-entry {
                @extend .mb-4, .text-color-4, .text-medium;
                display: flex;
                justify-content: center;

                .label {
                    @extend .mr-10;
                }

                .value {

                }
            }
        }
    }

    &.questionnaire {
        .title {
            @extend .text-uppercase;
        }
    }

    &.disclaimer, &.opt-in, &.opt-out {

        .message-content .title {
            background: $lightBrown;
            color: $accentBrown;
            font-weight: 500;
        }
    }
}
