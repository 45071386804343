@import "../../../assets/vars.scss";
@import "src/assets/colors";

.header-container {
    display: flex;
    justify-content: center;

    .main-header {
        flex: 1;
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        display: flex;
        justify-content: space-between;
        height: $headerHeight;
        flex-shrink: 0;
        max-width: 1920px;

        .left,
        .right {
            display: flex;
            align-items: center;
        }

        .left {
        }

        .right {
            button,
            .language-button {
                height: 3.5rem;
            }
        }

        .user-dropdown {
            width: 156px;
            z-index: 100;

            .dropdown-inner {
                .dropdown-row {
                    &.logout {
                        color: $accentRed;
                    }
                }
            }
        }
    }
}
