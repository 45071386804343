@import 'src/assets/colors';

.lazy-load-list {
    width: 100%;
    height: 100%;
    background: $white;
    overflow: hidden;

    .inner {
        height: 100%;
        overflow: auto;

        .no-results {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    .lazy-load-list-async-container-and-waypoint {
        min-height: 35px;
    }
}
