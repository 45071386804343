@import "src/assets/colors";
@import "src/assets/text-styles";

.manage-topic {

    .hc-picker {
        flex: 0.5;
        margin-right: 20px;
        min-width: 16.25rem;
    }
}
