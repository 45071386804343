@import "src/assets/vars";
@import "src/assets/colors";

.breadcrumbs {
    display: flex;
    align-items: center;

    .breadcrumb {
        display: flex;
        user-select: none;
        padding-right: 0.625rem;
        cursor: pointer;
        color: $textColor4;

        .right-arrow {
            padding-left: 0.625rem;
        }

        &:last-of-type {
            color: $accentBlue;
        }
    }
}
