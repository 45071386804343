@import 'src/assets/animations';

.mpdz-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.0);
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    transition: all 220ms ease-in-out;
    animation-name: alert-open;
    animation-duration: 220ms;
    z-index: 1000000;

    &.open {
        z-index: 1000000;
        background: rgba(0, 0, 0, 0.25);
    }
}
