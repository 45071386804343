@import "colors";

.text-regular {
    font-family: "Rubik", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
}

.text-bold {
    font-family: "Rubik", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
}

.text-medium {
    font-family: "Rubik", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
}

.text-light {
    font-family: "Rubik", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
}

.text-32 {
    font-size: 2rem;
}

.text-18 {
    font-size: 1.125rem;
}

.text-16 {
    font-size: 1rem;
}

.text-15 {
    font-size: 0.9375rem;
}

.text-14 {
    font-size: 0.875rem;
}

.text-12 {
    font-size: 0.75rem;
}

.text-caps-lock {
    text-transform: uppercase;
}

.text-color-1 {
    color: $textColor1;
}

.text-white {
    color: $white;
}

.text-color-3 {
    color: $textColor3;
}

.text-color-4 {
    color: $textColor4;
}

.text-color-accent-blue {
    color: $accentBlue;
}

.text-color-light-blue {
    color: $lightBlueWithoutWhite;
}

.text-color-accent-green {
    color: $accentGreen;
}

.text-color-red {
    color: $accentRed;
}

.text-italic {
    font-style: italic;
}
