.add-questionnaire-modal {

    .add-questionnaire-inner {
        display: flex;
        flex-direction: column;
        width: 23.375rem;
        min-height: 19.75rem;
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 2.5rem 3.5625rem;

        .footer {
            display: flex;
            margin-left: auto;
            margin-top: auto;
        }
    }
}
