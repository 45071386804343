@use "sass:color";
@import "src/assets/colors";
@import "src/assets/text-styles";

.messenger-message-bar {
    min-height: 4rem;
    background-color: $white;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;

    .message-bar-form {
        flex: 1;
        height: 100%;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        display: flex;
        align-items: center;
    }

    button.colorful-icon-button.variant-blue-white {
        &:disabled {
            background: color.scale($background, $blackness: 10%);
        }
    }

    .message-bar-bottom {
        min-height: 4rem;
        display: flex;
        align-items: center;
        flex: 1;
    }

    .message-input-field {
        width: 100%;
        resize: none;
        outline: none;
        border: none;
        padding-left: unset;
        @extend .text-color-4;
        @extend .text-medium;
        @extend .text-14;

        &::placeholder {
            @extend .text-color-4;
            @extend .text-medium;
            @extend .text-14;

            opacity: 1;
        }
    }
}
