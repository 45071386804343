@import "src/assets/colors";
@import "src/assets/text-styles";

.dropdown-top-wrapper {
    .dropdown-anchor-wrapper {
        display: flex;
    }

    &.edge-bottom-left,
    &.edge-bottom-right {
        .dropdown {
            transform-origin: top;
        }
    }

    &.edge-top-left,
    &.edge-top-right {
        .dropdown {
            transform-origin: bottom;
        }
    }

    .dropdown {
        z-index: 10;
        position: fixed;
        overflow: hidden;
        background: $white;
        border-radius: 10px;
        border: 2px solid $borderColor;

        &.animation-type-fade {
            height: auto;
        }

        &.open {
            height: fit-content;
        }

        .dropdown-inner {

            @extend .text-color-4;
            @extend .text-16;

            .dropdown-row {
                user-select: none;
                cursor: pointer;
            }
        }
    }
}
