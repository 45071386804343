@import "src/assets/misc";
@import "src/assets/text-styles";
@import "src/assets/colors";

.tabs-container {
    background: $background;

    .tabs {
        display: flex;
        border-top-left-radius: 0.9375rem;
        border-top-right-radius: 0.9375rem;
        transition: all 200ms ease-in-out;
        overflow: hidden;

        .tab {
            @extend .flex-row, .align-center;
            @extend .text-medium, .text-14, .text-color-3;
            background: $tabBackground;
            text-transform: uppercase;
            flex: 1;
            height: 5rem;
            cursor: pointer;
            position: relative;
            transition: all 200ms ease-in-out;

            &.active {
                background: #fff;
                color: $textColor1;
            }

            &.disabled {
                pointer-events: none;
            }
        }
    }
}

