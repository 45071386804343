@import "src/assets/text-styles";
@import "src/assets/misc";

.questionnaire-form {
    .answer {
        @extend .flex-row, .ml-14, .text-14, .align-center;

        padding: 1rem 0.625rem 1rem 1rem;

    }

    .answer-action-icon {
        @extend .text-color-3;
        cursor: pointer;

        &:hover {
            color: $accentBlue;
        }

        &.red {
            &:hover {
                color: $accentRed;
            }
        }

    }

    .item-action {
        @extend .text-color-3;
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;

        &:hover {
            color: $accentBlue;
        }
    }
}