@import "src/assets/colors";

.dropdown-row {
    padding: 0.75rem;
    transition: all 220ms ease-in-out;

    &:hover {
        background: $lightBlueWithWhite;
    }
}
