@import "src/assets/colors";

.mpdz.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;

    &.padded {
        padding-top: 0.6rem;
    }

    &.focus {
        .checkmark {
            border: 1px solid $accentBlue;
        }
    }

    .checkbox-label {
        padding-left: 0.45rem;
        font-size: 0.875rem;
        //font-weight: bold;
        color: $textColor3;
    }

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        transition: all 150ms ease-in-out;
    }

    $checkmarkSize: 1.125rem;

    /* Create a custom checkbox */
    .checkmark {
        position: relative;
        height: $checkmarkSize;
        width: $checkmarkSize;
        min-height: $checkmarkSize;
        min-width: $checkmarkSize;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 4px;
        border: 2px solid $textColor3;
        transition: all 150ms ease-in-out;
    }

    /* When the checkbox is checked, add a background */

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        //left: 0.3rem;
        //top: 0.05rem;
        //height: 0.6rem;
        //width: 0.28rem;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        left: 0.3rem;
        top: 0.065rem;
        width: 0.2rem;
        height: 0.5rem;
    }

    &.type-blue {
        input:checked ~ .checkmark {
            border-color: $accentBlue;

            &:after {
                border-color: $accentBlue;
            }
        }
    }
}
