@import "src/assets/text-styles";

.users {
    .entity-toolbar {
        &.open {
            .users-search-input {
                margin-right: 0;
            }
        }

        .user-role-picker {
            @extend .text-14;
            flex: 1;
            max-width: 11.25rem;
        }

        .users-search-input {
            transition: margin-right 150ms ease-in-out;
            margin-right: 1.25rem;
            flex: 1;
            max-width: 19.6875rem;
            margin-left: auto;
        }
    }
}
