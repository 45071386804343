@import "src/assets/vars";
@import "src/assets/colors";
@import "src/assets/text-styles";

.impersonation-button-container {
    display: flex;
    flex-direction: column;
    height: 3.5rem;
    justify-content: center;
    padding: 0.375rem 0.8125rem 0.375rem 1.125rem;
    background-color: $lightBlueWithWhite;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    margin-left: 0.625rem;

    .role-text {
        @extend .text-12;
        @extend .text-color-3;
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .strikeout {
        div {
            text-decoration: line-through;
        }
    }

    .info {
        display: flex;
        @extend .text-14;
        @extend .text-medium;

        .dropdown-indicator {
            @extend .text-color-3;

            transition: all 150ms ease-in-out;
            margin-left: 0.5625rem;

            &.open {
                transform: rotateZ(180deg);
            }
        }
    }
}
