@import "src/assets/text-styles";
@import "src/assets/colors";

.circular-initials {
    width: 2.5rem;
    height: 2.5rem;
    background: $lightBlueWithoutWhite;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 220ms ease-in-out;

    @extend .text-regular;
    @extend .text-16;
    @extend .text-color-4;

    span {
        text-overflow: ellipsis;

        overflow: hidden;
        white-space: nowrap;
    }

    &.selected {
        background: $accentBlue;
        color: $white;
    }
}
