@use "sass:color";
@import "src/assets/colors";
@import "src/assets/text-styles";

.message-bar-attachments {
    padding-top: 1.25rem;
    padding-bottom: 0.2rem;
    display: flex;

    .file-attachment {
        flex-direction: column;
        cursor: pointer;
        width: 4rem;
        height: 4rem;

        .icon-tabler {
            margin-right: 0.75rem;
            min-height: 2rem;
            min-width: 2rem;
        }

        .filename {
            margin-top: 0.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }

        padding: 1rem;
        display: flex;
        align-items: center;

        @extend .text-regular;
        @extend .text-12;
        @extend .text-color-1;
    }

    .attachment {
        margin-right: 1rem;
        cursor: pointer;
        position: relative;
        z-index: 0;

        .remove-attachment-btn {
            min-width: 1.75rem;
            min-height: 1.75rem;
            max-width: 1.75rem;
            max-height: 1.75rem;
            position: absolute;
            top: -20%;
            right: -20%;
            background: color.adjust($lightRedWithWhite, $alpha: 0.3);

            z-index: 1;
        }

        &:hover {
            .image-tint {
                width: 4rem;
                height: 4rem;
                top: 0;
                left: 0;

                max-width: 100%;
                max-height: 100%;
            }
        }


        .image-tint {
            transition: max-height 220ms ease-in-out, max-width 220ms ease-in-out, top 220ms ease-in-out, left 220ms ease-in-out;
            top: 50%;
            left: 50%;
            max-height: 0;
            max-width: 0;

            position: absolute;
            background: color.adjust($accentBlue, $alpha: -0.6);
            object-fit: cover;
            border-radius: 10px;
        }

        .image-tint,
        img {
            object-fit: cover;
            width: 4rem;
            height: 4rem;

            border-radius: 10px;
        }
    }
}
