@use "sass:color";
@import "src/assets/colors";

.lead-list-item {
    display: flex;
    background: $white;
    cursor: pointer;
    transition: all 100ms cubic-bezier(0.86, 0, 0.07, 1);
    border-bottom: 1px solid $lightBlueWithWhite;
    align-items: center;
    user-select: none;
    flex: 1;
    min-height: 4.375rem;
    height: 4.375rem;

    &:hover {
        background: color.change($lightBlueWithWhite, $alpha: 0.08);
    }

    &.opt-in {
        .circular-initials {
            background: $accentGreen;
            color: $white;
        }
    }

    &.opt-out {
        .circular-initials {
            background: $accentRed;
            color: $white;
        }
    }

    &.opt-unknown {
        .circular-initials {
            background: $lightBlueWithoutWhite;
        }
    }

    &.selected {
        background: $lightBlueWithWhite;
    }

    &.multi.selected {
        .circular-initials {
            background: $accentBlue;
            color: $white;
        }
    }

    .lead-status {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    }

    .initials-container {
        min-width: 4rem;
        display: flex;
        justify-content: center;
    }

    .reason-chip {
        padding: 0.4rem 0.8rem;
        background: $lightBlueWithoutWhiteDarker;
        color: $textColor1;
        font-size: 0.875rem;
        border-radius: 0.5rem;
        margin-right: 0.5rem;
        margin-left: auto;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 7.5rem;
    }
}
